import { clientDao } from '@/service';

export default {
  actions: {
    getCompanyClients({ commit, getters}) {
      return new Promise((resolve, reject) => {
        clientDao
          .getCompanyClients({ company_id: getters.company.id })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar clientes da empresa!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

    getClientEquipments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        clientDao
          .getClientEquipments(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar equipamentos!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

  },
};